import React, { FC } from "react";

export interface CheckboxProps {
  label?: string;
  subLabel?: string;
  name: string;
  callback:Function;
}

const Checkbox: FC<CheckboxProps> = ({ subLabel = "", label = "", name, callback }) => {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        {label && (
          <div className="mr-1 text-sm">
            <label
              htmlFor={name}
              className="text-paragraph-small text-black dark:text-white"
            >
              {label}
            </label>
            {subLabel && <p className="text-neutral-500">{subLabel}</p>}
          </div>
        )}
        <input
          id={name}
          name={name}
          type="checkbox"
          defaultChecked={true}
          className="focus:ring-action-primary h-4 w-4 text-primary border-primary"
          onChange={(e)=>{callback(name, e)}}
        />
      </div>

    </div>
  );
};

export default Checkbox;
