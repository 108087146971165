import React, { FC, useEffect, useRef, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Button from "components/Button/Button";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox3";

import {transferNFT} from 'api/guapi'


export interface ModalReportItemProps {
  show: boolean;
  onCloseModalReportItem: () => void;
  nftIds:string[];
  cryptoPrices:number[];
  cheapNFTPrices:number[];

}

const FILTERS = [
  { name: "ETH" },
  { name: "GODS" },
  { name: "IMX" },
  { name: "USDC" },
];

type objtype={
  [index:string]:number
}


const ModalReportItem: FC<ModalReportItemProps> = ({
  show,
  onCloseModalReportItem,
  nftIds,
  cryptoPrices,
  cheapNFTPrices
}) => {

  const [selected, setSelected] = useState<number>(0); // selected quality
  const [sellNFTQuantity, setSellNFTQuantity] = useState<number>(0); // 몇개팔것인지

  const [currency_sell, setCurrencySell] = useState<string>('ETH'); // image
  const [nft_price, setNftPrice] = useState<number>(0); // image


  const [nft_price_usd, setNFTPriceUSD] = useState<string>
    ((  (cryptoPrices[0]* cheapNFTPrices[0]) / (1.07)).toFixed(2)); // image

  const [address_transfer, setAddressTransfer] = useState('');
  const [list_transfer, setListTransfer] = useState<string[]>([]);

  const [text_transfer, setTextTransfer] = useState<string>('Send NFT');


  // console.log('pp',cheapNFTPrices[0], cryptoPrices[0])
  // console.log('nft_price_usd', ((cryptoPrices[0]* cheapNFTPrices[0]) / (1.07)).toFixed(2),  nft_price_usd);
  
  const handleSelected = (e: any) =>{
    console.log('selected',e);
    setCurrencySell(e.name);

    var crypto2num:objtype={
      'ETH':0,'GODS':1,'IMX':2,'USDC':3
    }
    setSelected(crypto2num[e.name]);    
    setNFTPriceUSD((nft_price * cryptoPrices[crypto2num[e.name]]).toFixed(2));
  }

  const handleClick = () =>{
    // get lowest
    console.log('cheapNFTPrices', cheapNFTPrices);
    var nft_price_ = cheapNFTPrices[selected]/ (1.07);
    setNftPrice(nft_price_)
    setNFTPriceUSD((nft_price_ * cryptoPrices[selected]).toFixed(2));
  }

  const handleQuantity = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if( isNaN( Number(event.target.value)) == false ){
      setSellNFTQuantity(Number(event.target.value));
    }
  
  }

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if( isNaN( Number(event.target.value)) == false ){
      var nft_price_ = Number(event.target.value);
      var nft_price_usd_ = nft_price_*1;
      setNftPrice(nft_price_);
      setNFTPriceUSD((nft_price_usd_ * cryptoPrices[selected]).toFixed(2));
    }
  };

  const handleClose = () => {
    setSelected(0);
    setNftPrice(0);
    setNFTPriceUSD((0 * cryptoPrices[0]).toFixed(2));
    onCloseModalReportItem();
    setTextTransfer('Send NFT');
  }
  
  const handleTransfer = () => {
    console.log('address', address_transfer, nftIds.slice(0,sellNFTQuantity));
    transferNFT(nftIds.slice(0,sellNFTQuantity), address_transfer).then(is_success=>{
      if(is_success){
        setTextTransfer('Success transfer');
      }
    });
  }
    
  const handleAddressTransfer= (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setAddressTransfer(event.target.value);
  };
  const renderContent = () => {
    if(nft_price_usd == '0.00' || nft_price_usd != ((cryptoPrices[0]* cheapNFTPrices[0]) / (1.07)).toFixed(2)) {
      console.log('update price');
      handleClick();
    }

    return (
      <div>

      <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
      <thead className="bg-neutral-50 dark:bg-neutral-800">
        <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
        <tr key='quantity'>
          <td className="px-6 py-4">
            Quantity
          </td>
          <td className="px-6 py-4">
            <input className="w-16 h-8 dark:text-neutral-900" type='number' min="0"
            onChange={handleQuantity} value ={sellNFTQuantity}
            /> of {nftIds.length}
          </td>
        </tr>
        <tr key='usd2'>
          <td className="px-6 py-4">
          Purchased Price
          </td>
          <td className="px-6 py-4">
            ${(Number(nft_price_usd)*1.07).toFixed(2) } (includes fee)
          </td>
        </tr> 

      </tbody>
    </table>
    <div className="relative text-center">
      <input className="mb-1 mt-1 mr-3 w-full dark:text-neutral-900" 
              placeholder="Type wallet address 0x24.." type='text' value={address_transfer} onChange={handleAddressTransfer}/>
    </div>

    <div className="relative text-center">
      <ButtonPrimary className="mt-3 text-xs font-medium" onClick={handleTransfer}
        disabled={text_transfer=='Send NFT'?false:true}
      >{text_transfer}</ButtonPrimary>
    </div>

    </div>

    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={handleClose}
      contentExtraClass="max-w-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Send NFT"
    />
  );
};

export default ModalReportItem;
