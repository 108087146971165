import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import { OrderType } from "data/types";

export interface TableProps {
  className?: string;
  orders?: OrderType[]|any;
  clicked: Function;
}

const Table: FC<TableProps> = ({
  orders, clicked
}) => {

  const onClick = (item_name: string) =>{
    clicked(item_name);
  }
  return (
    <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
      <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
        <thead className="bg-neutral-50 dark:bg-neutral-800">
          <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
            <th scope="col" className="px-6 py-3">
              No.
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Price
            </th>
            <th scope="col" className="px-6 py-3">
              Arbitrage
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
          {orders.map((item:any) => (
            <tr key={item.gid} onClick={() =>{
              onClick(item.name); // soldout 이 아닐때만 구매가능
            }}>
              <td className="px-6 py-4">
                {`${item.idx}`}
              </td>
              <td className="px-6 py-4">
                {`${item.name}`}
              </td>
              <td className="px-6 py-4">
                {`ETH: ${ isNaN(item.eth_price)? "NAN": '$'+item.eth_price.toFixed(2)} `}
                {`GODS: ${ isNaN(item.gods_price)? "NAN": '$'+item.gods_price.toFixed(2)} `}
              </td>
              <td className="px-6 py-4">
                {`${ (item.rate*100 - 100).toFixed(2)}%`}
              </td>              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
