import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Input from "components/Input/Input";
import {load_gu_card_info, gu_info, _decode} from 'api/gu_gameapi';
// import { Link } from "react-router-dom";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Table from "components/CardNFT/TableCardsForTour";
import { CardType } from "data/types";
import ModalCard from "components/CardNFT/ModalCard";
import {getAssetsByUser,transferNFT, orderListV2, buyOrders, orderListWithCheap, tokenPrice, buyLog} from 'api/guapi';
import LoadingOverlay from 'react-loading-overlay';

import { useLocation } from "react-router-dom"


export interface PageSearchV2Props {
  className?: string;
}

const Transfer: FC<PageSearchV2Props> = ({ className = "" }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)



  useEffect(() => {
    if(started == false) {
      console.log('init once');
      setStarted(true);
      setLoading(true);

      const searched = params.get('n');
      console.log('searched', searched);
      if(searched!=null) {
        setMessage(searched)
        load_gu_card_info().then(()=>{
          handleEnter({key:'Enter'}, searched);
        })
      }
      else {
        load_gu_card_info().then(()=>{
          handleEnter({key:'Enter'});
        })
      }
      
    }
  })
  const [search_text, setMessage] = useState('GU_1_4_BBgBBgBDWBDWBDrBDrBEXBEXBGCBGCKAgKAgKAqKAqLAELAELALCAlCAlCAnCEkCEkCEqCEqCErCErGAVGAVGAgIBm');
  const [search_enter_text, setSearchEnter] = useState('');
  const [result_searched, setResultSearch] = useState(false);
  const [address_transfer, setAddressTransfer] = useState('');
  const [list_transfer, setListTransfer] = useState<string[]>([]);

  const [arrayCards, setArrayCards] = useState<CardType[]>([]);
  const [arrayOrderIds, setArrayOrderIds] = useState<string[]>([]);
  const [arrayOrderIdsAll, setArrayOrderIdsAll] = useState<string[]>([]);
  const [arrayOrdersAll, setArrayOrdersAll] = useState<any[]>([]);

  // overlay
  const [overlayImage, setOverlayImage] = useState(false);
  const [overlayImageId, setOverlayImageId] = useState('');
  const [overlayPrice, setOverlayPrice] = useState('');


  const [started, setStarted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [price_sum_string, setSumPrice] = useState<string>('');

  const [valid, setValid] = useState<boolean>(false);

  const closeModalOverlay = () => setOverlayImage(false);

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessage(event.target.value);
  };


  const getUserHold = async(cardArray:CardType[], sender:any) =>{
    var list_transfer_function = [];
    for (const card of cardArray) {
      if(sender!=null)
        list_transfer_function.push(getAssetsByUser(sender,card.nft.name, card.nft.proto, [4]));
    }
    return Promise.all(list_transfer_function);
  }

  const getOrderIds = async (cardArray:CardType[]) =>{
    var list_card_order_id:any[] = [];
    var list_card_order_id_func = [];
    var priceETH = await tokenPrice('ETH');

    for (const card of cardArray) {
      list_card_order_id_func.push(orderListWithCheap(card.nft.name, card.nft.proto));
    }
    return Promise.all(list_card_order_id_func).then(result => {
      var idx = 0;
      for (const order of result) {
        if(order !== undefined) {
          var order_list_ = [];
          for (const oo_ of order) {
            var price_usd:string = (priceETH* oo_.price_human*1).toFixed(2);
            order_list_.push({name:oo_.nft_name, order_id:oo_.order_id, price_usd})
          }
          list_card_order_id.push({idx,order_list_});
        }
        idx++;
      }
      return list_card_order_id;
    });
  }

  const handleEnter = async (e: { key: string; }, searched:string = '') =>{
    if(e.key === 'Enter'){
      setLoading(true);
      var now_search = search_text;
      if(searched!=='')
        now_search = searched;
      
      setSearchEnter(now_search);
      var r = _decode(now_search);
      console.log('r', r, now_search);
      console.log('gu info', Object.keys(gu_info).length);
      // result
      var array_:CardType[]= [];
      if(r.result !=undefined) {
        for (const [key, value] of Object.entries(r.result)) {
          console.log(key,value, gu_info[key].gid);
          var t:CardType={nft:{
            name:gu_info[key].name,
            bgImage:`https://card.godsunchained.com/?id=${gu_info[key].gid}&q=4`,
            proto:gu_info[key].gid
          }, 
          id:key,
          count:value,
          user_hold:0,
          user_transfer:0,
          price_usd:'0',
          rarity:gu_info[key].rarity,
          set:gu_info[key].set
        };
          array_.push(t);
        }
      }
      else {

      }
      console.log('array',array_);
      setArrayCards(array_);
      setResultSearch(r.success);

      /// load array
      const sender = localStorage.getItem('WALLET_ADDRESS');


      var test = array_.filter(a=>{
        if(a.set == 'genesis' || a.set == 'trial' || a.set == 'etherbots' || a.set == 'verdict' ||
            a.rarity == 'legendary' || a.rarity =='mythic'
        ) return true;
        else return false;
      })
      console.log('cards', test);
      if(test.length>0){
        setValid(false);
      }
      else{
        setValid(true);
      }


      setArrayCards(array_);
      // setArrayOrderIds(list_orderids_);
      // setArrayOrderIdsAll(list_orderidsall_);
      // setArrayOrdersAll(list_orderinfo_all_);

      // load done
      // setListTransfer(list_transfer_);
      console.log('done');
      setLoading(false);
    }
  }

  const onMouseOver = (card:CardType, is_show:boolean)=> {
    setOverlayImage(is_show);
    setOverlayImageId(gu_info[card.id].gid);
    setOverlayPrice(card.price_usd);
    console.log(is_show, gu_info[card.id].gid);
  }
  
  const handleAddressTransfer= (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setAddressTransfer(event.target.value);
  };

  const handleTransfer = () => {
    console.log('address', address_transfer, list_transfer);
    transferNFT(list_transfer, address_transfer);
  }

  const handleBuy = () => {
    // get 
    console.log('arrayOrderIds', arrayOrderIds);
    if(arrayOrderIds.length===0){
      return;
    }
    buyOrders(arrayOrderIds).then(a=>{
      var volume = 0;
      for(const [order_id, order_result] of Object.entries(a.result)) {
        var status:any = order_result.status;
        console.log(order_id,'status',status);
        if(status == "success"){
          var idx= arrayOrdersAll.findIndex(a=>a.order_id==order_id);
          if(idx !== -1) {
            volume = volume + Number(arrayOrdersAll[idx].price);
          }
        }
      }
      const sender = localStorage.getItem('WALLET_ADDRESS');
      if(sender!=null) {
        buyLog(sender,volume);
      }
      handleEnter({key:'Enter'});
    });
  }

  const handleBuyAll = () => {
    // get 
    console.log('arrayOrderIdsAll', arrayOrderIdsAll);
    if(arrayOrderIdsAll.length===0){
      return;
    }
    buyOrders(arrayOrderIdsAll).then(a=>{
      var volume = 0;
      for(const [order_id, order_result] of Object.entries(a.result)) {
        var status:any = order_result.status;
        console.log(order_id,'status',status);
        if(status == "success"){
          var idx= arrayOrdersAll.findIndex(a=>a.order_id==order_id);
          if(idx !== -1) {
            volume = volume + Number(arrayOrdersAll[idx].price_usd);
          }
        }
      }
      const sender = localStorage.getItem('WALLET_ADDRESS');
      if(sender!=null) {
        buyLog(sender,volume);
      }
      handleEnter({key:'Enter'});
    });
  }

  const handleQuantity= async (n:number, id:string) =>{
    setLoading(true);
    console.log('handleQuantity',id, n);
    var array_ = arrayCards;
    var t= array_.find(a=>a.id === id);
    if(t!==undefined) t.user_transfer = n;
    /// update list_transfer
    var list_transfer_ = [];
    /// update price_sum
    var price_sum = 0;


    const sender = localStorage.getItem('WALLET_ADDRESS');

    var results = await Promise.all([getOrderIds(array_), 
                        getUserHold(array_,sender)]);

    var result_transfer_ = results[1];
    var result_order_id = results[0];
    for (let i = 0; i < array_.length; i++) {
      var order_info = result_order_id.filter(a=>a.idx === i);
      // console.log('order_info', order_info);
      var card = array_[i];
      var result_ = result_transfer_[i];

      if(order_info.length>0) {
        price_sum = price_sum + card.user_transfer*Number(card.price_usd)*1;
      }

      if(card.user_hold >= card.count) {
        for (let index = 0; index < card.user_transfer; index++) {
          const assetId = result_.nftIds[index];
          list_transfer_.push(assetId);
        }            
      }
    }

    setArrayCards(array_.slice());
    setSumPrice(price_sum.toFixed(2));
    setListTransfer(list_transfer_);
    setLoading(false);

  }

  return (
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      {/* <HeadBackgroundCommon className="h-24 2xl:h-28" /> */}
      <Helmet>
        <title>GodsCombine</title>
      </Helmet>
      <div className="container">


        <ModalCard
          show={(overlayImage)}
          onCloseModalReportItem={closeModalOverlay}
          imageId={overlayImageId}
          price={overlayPrice}
        />



        <header className="max-w-2xl mx-auto mt-10 flex flex-col lg:-mt-15">

        <h1 className="mb-2 block text-lg ">Check your deck is valid or not. Enter your deckcode.</h1>

        <h1 className="mb-2 block text-lg ">- Banned sets:  Genesis, Trial of the Gods, Etherbots, Lights Verdict</h1>
        <h1 className="mb-2 block text-lg ">- Banned rarities:  Legendary, Mythic</h1>

        


        <h1 className="mb-5 block text-lg "> 
          <a className="" style={{color: 'green'}} href={"https://challonge.com/SupberbOwl3"} target="_blank">https://challonge.com/SupberbOwl3</a>
        </h1>


          <div className="relative">


            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                id="search-input"
                type="search"
                placeholder="Type deck-code and press enter"
                className="shadow-lg rounded-xl border-opacity-0"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                name="message"
                onChange={handleChange}
                value={search_text}
                onKeyPress={async (e)=>{
                  await handleEnter(e);
                } }
              />
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
          <span className="block text-sm mt-4 text-neutral-500 dark:text-neutral-300">
          Results for{" "}
          <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
            "{search_enter_text.length>10?(search_enter_text.substring(0,10)+"..."):''}"
          </strong>.
              {result_searched? <a className="ml-5" style={{color: 'green'}} href={'https://gudecks.com/decks/'+search_enter_text} target="_blank">View on GUDecks.com</a>:''}

             
          </span>
              <h1 className="block text-lg">{valid?'- This deck is valid.':'- This deck is not valid.'}  {valid == false? <a style={{color: '#ff00ff'}} target="_blank">
               Pink color means that banned cards in your deck 
              </a>:''}</h1>

        {/* <div className="flex">
            <div className="flex mt-2">
              <input className="mb-1 mt-1 mr-3 w-80 dark:text-neutral-900" 
              placeholder="Type wallet address 0x24.." type='text' value={address_transfer} onChange={handleAddressTransfer}/>
              <ButtonPrimary className="" onClick={handleTransfer} disabled={list_transfer.length<=0}>Transfer</ButtonPrimary>
              {
                arrayOrderIds.length!==0?
                <ButtonPrimary className="ml-3" onClick={handleBuy} disabled={arrayOrderIds.length<=0}>Buy</ButtonPrimary>:<ButtonPrimary className="ml-3" onClick={handleBuyAll} disabled={arrayOrderIdsAll.length<=0}>Buy All</ButtonPrimary>
              }
            </div>
          </div>           */}
        </header>
        
        
        <div className="container max-w-2l mx-auto">
          <main>
            {/* LOOP ITEMS */}
            {/* <div className="grid mt-8 lg:mt-10"> */}
            <LoadingOverlay
              active={loading}
              spinner
              text='Loading..'
              >
            </LoadingOverlay>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-5 lg:gap-8 mt-8 lg:mt-10">

        

              <Table address={address_transfer} handleQuantity={handleQuantity} cards={arrayCards.slice(0,10)} mouseOver={onMouseOver}/>
              <Table address={address_transfer} handleQuantity={handleQuantity} cards={arrayCards.slice(10,30)} mouseOver={onMouseOver}/>
            </div>
          </main>
        </div>
      </div>

    </div>
  );
};

export default Transfer;
