import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";

import React, { FC, ReactNode } from "react";
import Button from "components/Button/Button";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  subHeading1: string;
  subHeading2: string;
  btnText: string;
  donateBtnText: string;
  youtubeID: string;
  donateBtnCallback:any;
  download_href:string;
  free_download_href:string;
  mac_download_href:string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  subHeading1,
  subHeading2,
  btnText,
  donateBtnText,
  youtubeID,
  donateBtnCallback,
  download_href,
  free_download_href,
  mac_download_href
}) => {

  const btn1Callback = () =>{
    console.log('btn1');
    donateBtnCallback();
  }

  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading1}
          </span>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading2}
          </span>   
          <div>
            {!!donateBtnText&&<ButtonPrimary className="mr-5" onClick={()=>{btn1Callback()}}>{donateBtnText}</ButtonPrimary>}

            {
              btnText ?
              <a href={download_href} target="_blank" rel="noreferrer">
              <ButtonPrimary>{"Window (Premium)"}</ButtonPrimary>
            </a>:<ButtonSecondary className="mr-5" disabled>{"Window (Premium)"}</ButtonSecondary>
            }
          </div>    
          <div>
          {
            <a href={free_download_href} target="_blank" rel="noreferrer">
              <ButtonPrimary className="mr-5">{'Window (Free)'}</ButtonPrimary>
            </a>
          }
          {
            <a href={mac_download_href} target="_blank" rel="noreferrer">
              <ButtonPrimary className="mr-5">{'Mac (Free)'}</ButtonPrimary>
            </a>
          }
          </div>
        </div>
        {!!rightImg&&
          <div className="flex-grow">
            <img className="w-full" src={rightImg} alt="" />
          </div>
        }
      
      {!!youtubeID&&
        <div className="flex-grow aspect-w-16 aspect-h-6">
          {/* <img className="w-full" src={rightImg} alt="" /> */}
          <iframe className="w-full"
          src={`https://www.youtube.com/embed/${youtubeID}?autoplay=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="ncblog hero video"
        ></iframe>
        </div>
      }
      </div>
    </div>
  );
};

export default SectionHero;
