import SectionHero from "components/SectionHero/SectionHero3";
import SectionHero4 from "components/SectionHero/SectionHero4";

import godscombine from "images/godscombine.png";
import React, { FC ,useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

import {transferGods} from 'api/guapi'
import {get_member} from 'api/server_api'

const version_txt = "1.3"
const payment = '5'
const window_premium_link = "https://firebasestorage.googleapis.com/v0/b/kogods.appspot.com/o/GC%20tracker%20Setup%201.4.0.exe?alt=media&token=3cb4b75e-8338-4a37-98fd-5b9ae24d025e";
const windown_link = "https://firebasestorage.googleapis.com/v0/b/kogods.appspot.com/o/GC%20tracker%20Setup%201.3.0.exe?alt=media&token=d8ce2720-f8dc-4ab9-921c-d2629bfd9483";
const mac_link = "https://firebasestorage.googleapis.com/v0/b/kogods.appspot.com/o/GC%20tracker-1.1.0.dmg?alt=media&token=d36da3ee-70ca-4adb-bac2-046b1732c594"

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const [validDonation, setValidDonation] = useState<boolean>(false);

  const donateCallback=()=>{
    console.log('donate');
    transferGods(payment, '0x0d215207AD6A1218c3073EB750eBC37dad2c9e50').then(r=>{
      console.log('success', r);
      try {
        if(r.result.length>0){
          if(r.result[0].status=="success"){
            // const address = localStorage.getItem('WALLET_ADDRESS');
            localStorage.setItem('DONATION', "true");
            localStorage.setItem('GC_VERSION', version_txt);
            setValidDonation(true);
          }
          else{
            console.log('fail1');
          }
        }
        else{
          console.log('fail2');
        }
      } catch (e) {
        console.log('fail3',e);
      }
    }).catch(e=>{
      console.log('fail4',e);
    });
  }

  const isDonationValid=()=>{
    var valid = false;
    const isDonation = localStorage.getItem('DONATION');
    const gcVersion = localStorage.getItem("GC_VERSION");
    console.log('gc version', gcVersion, version_txt);
    if(isDonation == 'true' && gcVersion == version_txt){
      valid = true;
    }
    setValidDonation(valid);
  }

  useEffect(() => {
    console.log('use effect');
    isDonationValid();
    const address = localStorage.getItem('WALLET_ADDRESS');
    if(address!=null) {
      
      get_member(address.toLowerCase()).then(info=>{
        console.log('is_member', info.result);
        setValidDonation(info.result)
      });
    }
  }, [])

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Info</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={""}
          heading="👋 About GC tracker"
          btnText={validDonation?"Window":""}
          donateBtnText={`Donate ${payment} GODS`}
          subHeading="GC Tracker is a desktop application for Gods Unchained. This tool will display all the cards in your deck as well as the drawing odds. The free packaged version can be downloaded from here. You could also check codes at github. (https://github.com/passionbull/gc-tracker)"
          // href="/single-template-3/this-is-single-slug"
          youtubeID="p_ZoPCDjDpc"          
          subHeading1={`To download premium version, please donate $${payment} GODS or trade cards in godscombine marketplace. Once you complete condition, download button is activated. The premium version of GU Tracker also tracks your opponent's deck as they play cards. Any feedback is welcome via Twitter.`}
          subHeading2="Just install and use it. A guide video is attached."
          download_href={window_premium_link}
          donateBtnCallback={donateCallback}
          free_download_href={windown_link}
          mac_download_href={mac_link}
        />

        <SectionHero4
          rightImg={godscombine}
          heading="👋 About Godscombine"
          btnText=""
          donateBtnText=""
          youtubeID=""          
          download_href=""
          subHeading="Godscombine is a nft marketplace for gods unchained powered by ImmutableX. See the lowest price across each currency on one panel. "

          subHeading1="Our fee is as low as 0.5% which is great for flipping cards and cheaper overall prices!"

          subHeading2="All functions of godscombine are based and enforced by the immutable api. Click on the price you like, confirm the amount, and card name before completing the transaction."

          donateBtnCallback={donateCallback}
          
        />

        {/* <SectionFounder />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionStatistic />
        </div>

        <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
