import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import { OrderType } from "data/types";

export interface TableProps {
  className?: string;
  orders?: OrderType[]|any;
  clicked: Function;
}

const Table: FC<TableProps> = ({
  orders, clicked
}) => {

  const onClick = (order_id: string) =>{
    clicked(order_id);
  }
  return (
    <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
      <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
        <thead className="bg-neutral-50 dark:bg-neutral-800">
          <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
            <th scope="col" className="px-6 py-3">
              Price
            </th>
            <th scope="col" className="px-6 py-3">
              Token
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
          {orders.map((item:any) => (
            <tr key={item.order_id} onClick={() =>{
              if(!item.soldout) onClick(item.order_id); // soldout 이 아닐때만 구매가능
            }}>
              <td className="px-6 py-4">
                {`${ isNaN(item.price)? "fail to load price api": '$'+item.price}`}
              </td>
              <td className="px-6 py-4">
                {`${item.soldout? 'Purchased': item.quantity+" "+item.token_type}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
