import React, { FC, useState, useEffect} from "react";
import Logo from "components/Logo/Logo";
import Navigation from "components/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";

import MenuBar from "components/MenuBar/MenuBar";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import { useAppDispatch } from "app/hooks";

import {setImxWallet} from "app/imxwallet/imxwallet";
import {linkSetup, getBalance} from 'api/guapi'


import PostActionDropdown from "components/PostActionDropdown/PostActionDropdownBalance";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const dispatch = useAppDispatch();
  const address = localStorage.getItem('WALLET_ADDRESS');
  const [buttonText, setButtonText] = useState<string>('Connect wallet');
  const [balances, setBalances] = useState<{ symbol: string; balance: any; worth: number; }[]>([]);

  var isUpdated = false;
  // var balances: { symbol: string; balance: any; worth: number; }[] = [];
  useEffect(() => {
    // console.log('init');
    if(isUpdated == false){
      isUpdated = true;
      setButtonText(address==null?'Connect wallet':address.substring(0,6)+"...")

      // update balance
      if(address!=null && balances.length == 0) {
        getBalance(address).then(a=>{
          if(balances.length ==0) {
            setBalances(a);
          }
        });
      }
    }
  })
  const onClick = () =>{
    console.log('click');
    if(address!=null) {
      localStorage.removeItem('WALLET_ADDRESS');
      setButtonText('Connect wallet');
    }
    else {
      linkSetup().then( (r) =>{
        var value = {
          address:r.address, connected:true
        }
        localStorage.setItem('WALLET_ADDRESS', r.address);
        setButtonText(r.address==null?'Connect wallet':r.address.substring(0,6)+"...")
        dispatch(setImxWallet(value));
      });
    }
  }
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            
            <DarkModeContainer />

            {/* <SearchDropdown /> */}
            {address!=null?<div className="px-7" />:  <div className="px-1" />}


            {address!=null?
            
            <PostActionDropdown
              containerClassName="h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700"
              iconClass="h-5 w-5"
              balanceInfo={balances}
            />
            : ""}
            {address!=null?<div className="px-6" />: ""}

            <ButtonPrimary className=""onClick={onClick}>{buttonText}</ButtonPrimary>
          </div>
          <div className="flex items-center xl:hidden">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
