import React, { FC } from "react";
import { CardType } from "data/types";
import NcImage from "components/NcImage/NcImage";

export interface TableProps {
  className?: string;
  cards?: CardType[]|any;
  mouseOver: Function;
  address: string;
  handleQuantity: Function;
}

const Table: FC<TableProps> = ({
  cards, 
  mouseOver,
  address,
  handleQuantity
  // clicked
}) => {

  const handleQuantity_ = (event: { target: { value: React.SetStateAction<string>; }; }, id:string)=>{
    if( isNaN( Number(event.target.value)) === false ){
      var num:number = Number(event.target.value);
      handleQuantity(num, id);
    }
  }
  const onClick = () =>{
    // clicked(0);
  }
  return (
    <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
      <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
        <thead className="bg-neutral-50 dark:bg-neutral-800">
          <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
            <th scope="col" className="px-6 py-3">
              Card name
            </th>
            <th scope="col" className="px-6 py-3">
              User has
            </th>
            <th scope="col" className="px-6 py-3">
              Image
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
          {cards.map((item:any) => (
            <tr key={item.id} 
            >
              <td className="px-6 py-4">
                {`${item.nft.name}`}  {`X ${item.count}`}
              </td>
              {
                item.user_hold>0?
                <td className="px-6 py-4 font-semibold" style={{color: '#ff00ff'}}>
                  {address===''?'':<input className="w-10 h-8 dark:text-neutral-900 pr-0 mr-1" type='number' min="0" 
                  onChange={(a)=>handleQuantity_(a, item.id)}
                  value ={item.user_transfer}
                  />}
                  {`${item.user_hold}`}
               </td>: 
                <td className="px-6 py-4">
                {`${item.user_hold}`} 
                </td> 
              }
              <td className="px-5" onClick={() =>{mouseOver(item ,true);}}>
                <NcImage className="w-10" src={`${item.nft.bgImage}`}/>
                ${item.price_usd}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
