import React, { FC, useEffect, useRef, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Button from "components/Button/Button";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox3";

import {sellNFT} from 'api/guapi'


export interface ModalReportItemProps {
  quality: number;
  show: boolean;
  onCloseModalReportItem: () => void;
  nftIds:string[];
  cryptoPrices:number[];
  cheapNFTPrices:number[];
  fee:number;
}

const FILTERS = [
  { name: "ETH" },
  { name: "GODS" },
  { name: "IMX" },
  { name: "USDC" },
];

type objtype={
  [index:string]:number
}


const ModalReportItem: FC<ModalReportItemProps> = ({
  quality,
  show,
  onCloseModalReportItem,
  nftIds,
  cryptoPrices,
  cheapNFTPrices,
  fee
}) => {

  const [selected, setSelected] = useState<number>(0); // selected quality
  const [sellNFTQuantity, setSellNFTQuantity] = useState<number>(0); // 몇개팔것인지

  const [currency_sell, setCurrencySell] = useState<string>('ETH'); // image
  const [nft_price, setNftPrice] = useState<number>(0); // image
  const [nft_price_usd, setNFTPriceUSD] = useState<string>('0'); // image


  const getDefaultFee = () =>{
    return quality ===1?1.025:1.07;
  }

  const handleSelected = (e: any) =>{
    console.log('selected',e);
    setCurrencySell(e.name);

    var crypto2num:objtype={
      'ETH':0,'GODS':1,'IMX':2,'USDC':3
    }
    setSelected(crypto2num[e.name]);    
    setNFTPriceUSD((nft_price * cryptoPrices[crypto2num[e.name]]).toFixed(2));
  }

  const handleClick = () =>{

    // get lowest
    console.log('cheapNFTPrices', cheapNFTPrices);
    var nft_price_ = cheapNFTPrices[selected]/ (getDefaultFee()+fee*0.01);
    setNftPrice(nft_price_)
    setNFTPriceUSD((nft_price_ * cryptoPrices[selected]).toFixed(2));
  }

  const handleQuantity = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if( isNaN( Number(event.target.value)) == false ){
      setSellNFTQuantity(Number(event.target.value));
    }
  
  }

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if( isNaN( Number(event.target.value)) == false ){
      var nft_price_ = Number(event.target.value);
      var nft_price_usd_ = nft_price_*1;
      setNftPrice(nft_price_);
      setNFTPriceUSD((nft_price_usd_ * cryptoPrices[selected]).toFixed(2));
    }
  };

  const handleClose = () => {
    setSelected(0);
    setNftPrice(0);
    setNFTPriceUSD((0 * cryptoPrices[0]).toFixed(2));

    onCloseModalReportItem();
  }
  

  const sellNFTasync=(sellTokenId:string, currency_amount:string, currency:string)=>{
    return new Promise<string>((
      resolve: (sucessValue: string) => void, 
      reject: (arg0: any) => void) => {
        console.log('sell', sellTokenId);
        sellNFT(sellTokenId, currency_amount, currency, fee).then(()=>{
          resolve('success');
        }).catch(()=>{
          resolve('fail');          
        });
      })
  }
  const handleSell = async () =>{
    console.log(nftIds.slice(0,sellNFTQuantity));
    for (const nftid of nftIds.slice(0,sellNFTQuantity)) {
      await sellNFTasync(nftid, nft_price.toFixed(8), currency_sell);
    }
  }


  const renderContent = () => {
    return (
      <div>

      <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
      <thead className="bg-neutral-50 dark:bg-neutral-800">
        <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
        <tr key='quantity'>
          <td className="px-6 py-4">
            Quantity
          </td>
          <td className="px-6 py-4">
            <input className="w-16 h-8 dark:text-neutral-900" type='number' min="0"
            onChange={handleQuantity} value ={sellNFTQuantity}
            /> of {nftIds.length}
          </td>
        </tr>
        <tr key='currency'>
          <td className="px-6 py-4">
            currency
          </td>
          <td className="px-6 py-4">
            <ArchiveFilterListBox className="" lists={FILTERS} handleSelected={handleSelected}/>
          </td>
        </tr>
        <tr key='price'>
          <td className="px-6 py-4">
            price
          </td>
          <td className="px-6 py-4">
            <input className="pl-2 w-24 h-8 dark:text-neutral-900" type='number' step='any' value={nft_price} onChange={handleChange}/>
            <ButtonPrimary className="ml-4 w-16 h-8 text-xs font-normal yellow" onClick={handleClick}>lowest</ButtonPrimary>
          </td>
        </tr>
        <tr key='usd1'>
          <td className="px-6 py-4">
            USD price
          </td>
          <td className="px-6 py-4">
            ${nft_price_usd}
          </td>
        </tr> 
        <tr key='usd2'>
          <td className="px-6 py-4">
            Purchased Price 
          </td>
          <td className="px-6 py-4">
            ${(Number(nft_price_usd)*(getDefaultFee()+fee*0.01)  ).toFixed(2) } (includes protocol fee: {Number(100* (getDefaultFee()+fee*0.01 - 1.0)).toFixed(2)}%)
          </td>
        </tr> 

      </tbody>
    </table>
    <div className="relative text-center">
      <ButtonPrimary className="mt-3 text-xs font-medium" onClick={handleSell}>List NFT</ButtonPrimary>
    </div>

    </div>

    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={handleClose}
      contentExtraClass="max-w-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Sell NFT"
    />
  );
};

export default ModalReportItem;
