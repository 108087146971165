import React from "react";

const LogoSvg = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="48.000000pt" height="48.000000pt" viewBox="0 0 48.000000 48.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
fill="#4f46e5" stroke="none">
<path d="M68 330 c-31 -24 -40 -65 -27 -114 12 -45 42 -66 94 -66 56 0 85 22
85 66 0 33 -1 34 -40 34 -22 0 -40 -4 -40 -10 0 -5 14 -10 30 -10 39 0 42 -36
4 -50 -41 -16 -63 -12 -91 16 -22 22 -25 32 -20 67 3 23 14 47 23 54 24 18 79
16 94 -2 16 -19 30 -19 30 0 0 37 -100 48 -142 15z"/>
<path d="M285 325 c-18 -17 -25 -35 -25 -64 0 -74 33 -111 99 -111 34 0 87 38
73 52 -7 7 -17 3 -31 -11 -26 -26 -65 -27 -91 -1 -27 27 -28 100 -2 123 23 21
75 22 92 2 16 -19 30 -19 30 0 0 41 -107 49 -145 10z"/>
</g>
</svg>

  );
};

export default LogoSvg;
