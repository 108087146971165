import { LinkParams, Link, ImmutableXClient, ImmutableOrderStatus, 
    ImmutableMethodResults, ImmutableMethodParams, ETHTokenType, ERC20TokenType, ERC721TokenType} from '@imtbl/imx-sdk';
import axios from 'axios';
import { NFTType } from "data/types";

require('dotenv').config();    
const link = new Link(process.env.REACT_APP_ROPSTEN_LINK_URL)


export async function buyLog(address:string, volume:number){
    console.log('buyLog',address, volume);
    var url = `https://us-central1-kogods.cloudfunctions.net/buy_api?address=${address}&volume=${volume}`
    const instance = axios.create({
        timeout: 5000,
      });    
    instance.get(url);
}

export async function linkSetup(){
    const res = await link.setup({})
    console.log('link', res);
    return res;
}


  const getListBalances = async (user: string) => {
    const publicApiUrl: string = process.env.REACT_APP_ROPSTEN_ENV_URL ?? '';
    const client = await ImmutableXClient.build({ publicApiUrl });
    const response = await client.listBalances({
      user,
    });
    return response;
  };
export async function getBalance(user_address:string){
    var balances = await getListBalances(user_address);
    // console.log('balance', balances.result.map(item=> item.balance.toString()));
    var price_eth = await tokenPrice('ETH');
    var price_gods = await tokenPrice('GODS');
    var price_imx = await tokenPrice('IMX');
    var price_set:any = {
        'ETH':price_eth, 'GODS':price_gods,'IMX':price_imx,'USDC':1
    }

    var result= balances.result.map( (item)=> {
        var decimal= Math.pow(10, 18);
        var balance_human:any= Number(item.balance.toString()) /decimal ;
        balance_human= balance_human.toFixed(3);
        var balance_dollar = balance_human*price_set[item.symbol];
        return {symbol:item.symbol,balance:balance_human, worth:balance_dollar};
    });
    return result;
}

export async function sellNFT(sellTokenId:string, currency_amount:string, currency:string, percentage_:number=0.5) {
    var token_name:any = {
        "GODS":"0xccc8cb5229b0ac8069c51fd58367fd1e622afd97",
        "IMX":"0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
        "USDC":"0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
    }
    var result = null;
    
    if(currency == 'ETH'){
        if(percentage_ == 0) {
            result = await link.sell({
                tokenId: sellTokenId,
                tokenAddress: "0xacb3c6a43d15b907e8433077b6d38ae40936fe2c",
                amount:currency_amount
            })
            return result;

        }
        else {
            result = await link.sell({
                tokenId: sellTokenId,
                tokenAddress: "0xacb3c6a43d15b907e8433077b6d38ae40936fe2c",
                amount:currency_amount,
                fees:[{
                  recipient: '0x0d215207AD6A1218c3073EB750eBC37dad2c9e50',
                  percentage:percentage_
                }]
              })
              return result;
        }
    }
    else {
        if(percentage_ == 0) {
            result = await link.sell({
                tokenId: sellTokenId,
                tokenAddress: "0xacb3c6a43d15b907e8433077b6d38ae40936fe2c",
                amount:currency_amount,
                currencyAddress:token_name[currency]
              })
            return result;
        }
        else {
            result = await link.sell({
                tokenId: sellTokenId,
                tokenAddress: "0xacb3c6a43d15b907e8433077b6d38ae40936fe2c",
                amount:currency_amount,
                currencyAddress:token_name[currency],
                fees:[{
                  recipient: '0x0d215207AD6A1218c3073EB750eBC37dad2c9e50',
                  percentage:percentage_
                }]
              })
            return result;
        }
    }
}

export async function buyOrders(buyOrderIds:Array<string>, percentage_:number=0.5){
    if(percentage_ == 0) {
        return await link.buy({
            orderIds:buyOrderIds,
        })
    }
    else {
        return await link.buy({
            orderIds:buyOrderIds,
            fees:[{
                recipient: '0x0d215207AD6A1218c3073EB750eBC37dad2c9e50',
                percentage:percentage_
            }]
          })
    }
}


export async function buyOrder(buyOrderId:string, percentage_:number=0.5){
    if(percentage_ == 0) {
        return await link.buy({
            orderIds:[buyOrderId]
          })
    }
    else {
        return await link.buy({
            orderIds:[buyOrderId],
            fees:[{
                recipient: '0x0d215207AD6A1218c3073EB750eBC37dad2c9e50',
                percentage:percentage_
            }]
          })
    }
}

function tokenName(type:string, address:string){
    // console.log(type, address);
    var token_name:any = {
        "0xccc8cb5229b0ac8069c51fd58367fd1e622afd97":"GODS",
        "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff":"IMX",
        "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48":"USDC"
    }
    if(type === 'ETH')
        return 'ETH';
    else {
        if(type === 'ERC20'){
        return token_name[address];
        }
    }
return 'NONE'
}


// =ImportJSON("https://api1.binance.com/api/v3/ticker/price?symbol=CAKEUSDT","/","noInherit, noTruncate")

export async function tokenPrice(type:string) {
    const instance = axios.create({
        timeout: 1000,
      });
    var result:number = 1;
    if(type =='ETH') {
        var url = "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&&vs_currencies=usd"
        try {
            var r = await instance.get(url);
            result = r.data['ethereum']['usd'];
        } catch (error) {
            result = NaN;
        }
    }
    else if (type == 'IMX') {
        var url = "https://api.coingecko.com/api/v3/simple/price?ids=immutable-x&&vs_currencies=usd"
        try {
            var r = await instance.get(url);
            result = r.data['immutable-x']['usd'];
        } catch (error) {
            result = NaN;
        }

    }
    else if(type == 'GODS'){
        var url = "https://api.coingecko.com/api/v3/simple/price?ids=gods-unchained&&vs_currencies=usd"
        try {
            var r = await instance.get(url);
            result = r.data['gods-unchained']['usd'];
        } catch (error) {
            result = NaN;
        }
    }
    return result;

}

function qualityName(n:number){
    const a:any = {
        1:'Diamond',
        2:'Gold',
        3:'Shadow',
        4:'Meteorite'
    }
    return a[n];
}

export async function getAssetImage(name:string, quality:number=1) {
    console.log('search start', name);
    const publicApiUrl: string = process.env.REACT_APP_ROPSTEN_ENV_URL ?? '';
    const client = await ImmutableXClient.build({ publicApiUrl });
    const info = await client.getAssets({
        collection:'0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
        name
    })
    var sampled_result = info.result.filter( a=>a.name?.toLowerCase() == (name.toLowerCase()));
    if(sampled_result.length ==0){
        sampled_result = info.result.filter( a=>a.name?.toLowerCase()?.includes(name.toLowerCase()));
    }
    console.log('info result', info.result)
    console.log('sampled_result', sampled_result)

    if(sampled_result.length>0){
        console.log(sampled_result[0].name);
        console.log(sampled_result[0].image_url);
        var t = sampled_result[0].image_url;
        if(t!=null) {
            t = t?.substring(0,t.length-1);
            t= t.concat(quality+"");
        }
        var proto:any = sampled_result[0].metadata;
        if(proto!=null){
            proto = Number(proto.proto);
        }
        if(sampled_result[0].name!=null && t!=null){
            let rrr:NFTType={name:sampled_result[0].name, bgImage:t, proto};
            return rrr;
        }
    }
    else{
        // one more search
        let rrr:NFTType=await getAssetImage(name.slice(0,name.length-1),quality);
        return rrr;
    }
    let rrr:NFTType={name:'', bgImage:'',proto:0};
    return rrr;
}

export async function getAssetsByUser(user_address:string, name:string, proto:number, quality:Array<number>=[2]) {
    try {
        var qualityString = quality.map(a=> qualityName(a));
        const publicApiUrl: string = process.env.REACT_APP_ROPSTEN_ENV_URL ?? '';
        const client = await ImmutableXClient.build({ publicApiUrl });
        const info = await client.getAssets({
            collection:'0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
            // name,
            user:user_address,
            metadata:JSON.stringify({
                "proto":[String(proto)],
                quality:qualityString
            })
        })
        var sampled_result = info.result.filter( a=>a.name?.toLowerCase() == (name.toLowerCase()));
        // console.log('getAssetsByUser', sampled_result);
        return {
            length:sampled_result.length,
            nftIds:sampled_result.map( a => a.token_id)
        }
    } catch (error) {
        return {
            length:0,
            nftIds:[]
        }
    }
}

export async function orderListWithCheapV2(name:string, proto:number, token:string) {
    var info:Array<any> = await orderList(name, proto, [4], token);
    if(info.length>0) return info.slice(0,5);
    return undefined;
}

export async function orderListWithCheap(name:string, proto:number) {
    var info:Array<any> = await orderList(name, proto, [4], 'ETH');
    if(info.length>0) return info.slice(0,5);
    return undefined;
}

export async function orderListV2(name:string, proto:number, quality:Array<number>=[1]) {
    var crypto_list = ['ETH','GODS','IMX','USDC'];
    var result: Array<any> = [];
    var cheapResult:Array<any>= []; 

    // async work
    var orderList_functions = [];
    for (const crypto of crypto_list) {
        orderList_functions.push(orderList(name, proto, quality, crypto));
    }
    var infoList = await Promise.all(orderList_functions);
    for (let i = 0; i < crypto_list.length; i++) {
        // const element = array[i];
        const crypto = crypto_list[i];
        var info:Array<any> = infoList[i];
        if(info.length>0) cheapResult.push(info[0]);
        else {
            cheapResult.push({nft_name: name,order_id: 0,price_human: 0,token_name: crypto});
        }
        result = result.concat(info);
    }
    return {result, cheapResult};

    // for (const crypto of crypto_list) {
    //     var info:Array<any> = await orderList(name, quality, crypto);
    //     if(info.length>0) cheapResult.push(info[0]);
    //     else {
    //         cheapResult.push({nft_name: name,order_id: 0,price_human: 0,token_name: crypto});
    //     }
    //     result = result.concat(info);
    // }
    // return {result, cheapResult};
}

export async function transferNFT(tokenIds:string[], receiver:string) {
    const gods_nft = '0xacb3c6a43d15b907e8433077b6d38ae40936fe2c';
    var payload: LinkParams.BatchNftTransfer = [
    ]
    for (const tokenId of tokenIds) {
        payload.push({
            type: ERC721TokenType.ERC721, // Must be of type ERC721
            tokenId, // the token ID
            tokenAddress: gods_nft, // the collection address / contract address this token belongs to
            toAddress: receiver, // the wallet address this token is being transferred to
        })
    }
    var is_success = false;
    try {
        await link.batchNftTransfer(payload);
        is_success = true;
    } catch (error) {
    }
    return is_success;
}

export async function transferGods(amount_:string, address_:string) {
    const transferResponsePayload = await link.transfer([
        {
          amount: amount_,
          symbol: 'GODS',
          type: ERC20TokenType.ERC20,
          tokenAddress: '0xccc8cb5229b0ac8069c51fd58367fd1e622afd97',
          toAddress: address_,
        }])
    return transferResponsePayload;
}

export async function orderList( name:string, proto:number, quality:Array<number>=[2],crypto:string='') {
    // get client
    const publicApiUrl: string = process.env.REACT_APP_ROPSTEN_ENV_URL ?? '';
    const client = await ImmutableXClient.build({ publicApiUrl });

    var qualityString = quality.map(a=> qualityName(a));
    
    var params:ImmutableMethodParams.ImmutableGetOrdersParamsTS = {
        status: ImmutableOrderStatus.active,
        sell_token_address: '0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
        sell_metadata: JSON.stringify({"proto":[String(proto)],"quality":qualityString}),
        // sell_token_name:name,
        order_by: 'buy_quantity',
        direction: ImmutableMethodParams.ImmutableSortOrder.asc,
    }
    if(crypto == 'ETH'){
        params.buy_token_type = ETHTokenType.ETH;
    }
    else if(crypto == 'GODS') {
        params.buy_token_type = ERC20TokenType.ERC20;
        params.buy_token_address = '0xccc8cb5229b0ac8069c51fd58367fd1e622afd97'
    }
    else if (crypto == 'USDC') {
        params.buy_token_type = ERC20TokenType.ERC20;
        params.buy_token_address = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
    }
    else if (crypto == 'IMX') {
        params.buy_token_type = ERC20TokenType.ERC20;
        params.buy_token_address = '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff'
    }
    else {

    }

    const orders = await client.getOrders(params)
    var test:any = orders.result;
    test = test.filter((a: any) =>(a.sell.data.properties.name.toLowerCase() == name.toLowerCase()));
    var info = [];
    for (const order of test) {
        var order_id = order.order_id;
        var price= order.buy.data.quantity_with_fees;

        var decimal= Math.pow(10, order.buy.data.decimals);
        var price_human= order.buy.data.quantity_with_fees /decimal ;

        var buy_type = order.buy.type;
        var buy_token= order.buy.data.token_address;
        var nft_name = order.sell.data.properties.name;
        var token_name = tokenName(buy_type, buy_token);
        info.push({nft_name,order_id, token_name, price_human});
    }
    return info.sort((a, b) => a.price_human - b.price_human);
}
