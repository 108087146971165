// 

import axios from 'axios';


// export async function get_trade_list(address:string){
    
//     try {
//         var url = `https://us-central1-kogods.cloudfunctions.net/get_trade_list`
//         const instance = axios.create({
//             timeout: 10000,
//           });
//         var info =await instance.get(url);
//         console.log('rr',info);
//         var t= info.data.list.filter((a: string)=> a=== address)
//         console.log(t);
//         return true;
//     } catch (error) {
//         console.log('error', error);
//         return false;
//     }
// }

export async function get_member(address:string){
    console.log('get_member',address);
    try {
        var url = `https://us-central1-kogods.cloudfunctions.net/get_member?address=${address}`
        const instance = axios.create({
            timeout: 5000,
          });
        var info =await instance.get(url);
        console.log('rr',info);
        return info.data;
    } catch (error) {
        console.log('error', error);
        return false;
    }
}

export async function get_cards(){
    try {
        var url = `https://us-central1-kogods.cloudfunctions.net/get_cards`
        console.log('url', url);
        const instance = axios.create({
            timeout: 5000,
          });
        var info =await instance.get(url);
        console.log('rr',info);
        var r = info.data;
        r.sort( (a:any, b:any) => b.unique_total - a.unique_total)
        return r;
    } catch (error) {
        console.log('error', error);
        return undefined;
    }
}