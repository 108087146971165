import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

interface IMXWalletState {
    value:{
        connected: boolean;
        address:string;
    }
}

const initialState: IMXWalletState = { value: {connected: false, address: ""}};

export const walletSlice = createSlice({
  name: "imxwallet",
  initialState,
  reducers: {
    setImxWallet: (state, action) => {
        state.value = action.payload
    },  },
});

export const {setImxWallet} =
    walletSlice.actions;

export const imxwallet_address = (state: RootState) =>
  state.imxwallet.value.address;

export default walletSlice.reducer;
