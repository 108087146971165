import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import MediaRunningContainer from "containers/MediaRunningContainer/MediaRunningContainer";
import PageSearchV2 from "containers/PageSearch/PageSearchV2";
import Transfer from "containers/PageSearch/PageTransfer";
import PageInfo from "containers/PageAbout/PageInfo";
import PageRanking from "containers/PageSearch/PageRanking";
import Tour from "containers/PageSearch/PageTournament";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageSearchV2 },
  { path: "/deck/:codes", component: Transfer },
  { path: "/deck/", component: Transfer },
  { path: "/SupberbOwl3", component: Tour },
  { path: "/about/", component: PageInfo },
  { path: "/info/", component: PageRanking },
  //
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContainer />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
      {/* MEDIA */}
      <MediaRunningContainer />
    </BrowserRouter>
  );
};

export default Routes;
