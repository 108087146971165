import React, { FC, useState } from "react";
import twFocusClass from "utils/twFocusClass";
import NcDropDown from "components/NcDropDown/NcDropDownBalance";
import ModalReportItem from "components/ModalReportItem/ModalReportItem";
import ModalHideAuthor from "./ModalHideAuthor";
import { useHistory } from "react-router";

export interface PostActionDropdownProps {
  containerClassName?: string;
  iconClass?: string;
  dropdownPositon?: "up" | "down";
  balanceInfo:{ symbol: string; balance: any; worth: number; }[];
}

const PostActionDropdown: FC<PostActionDropdownProps> = ({
  containerClassName = "h-8 w-8 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700",
  iconClass = "h-[18px] w-[18px]",
  dropdownPositon = "down",
  balanceInfo
}) => {
  // console.log('balanceInfo', balanceInfo);
  
  let actions = [
    {
      id: "eth",
      name: "ETH",
      icon: "https://design-system.immutable.com/currency_icons/currency--eth.svg",
      balance:balanceInfo.find(a=>a.symbol== 'ETH')
    },
    {
      id: "gods",
      name: "GODS",
      icon: "https://design-system.immutable.com/hosted-for-ds/currency-icons/currency--gods.svg",
      balance:balanceInfo.find(a=>a.symbol== 'GODS')
    },
    {
      id: "imx",
      name: "IMX",
      icon: "https://design-system.immutable.com/hosted-for-ds/currency-icons/currency--imx.svg",
      balance:balanceInfo.find(a=>a.symbol== 'IMX')
    },
    {
      id: "usdc",
      name: "USDC",
      icon: "https://design-system.immutable.com/hosted-for-ds/currency-icons/currency--usdc.svg",
      balance:balanceInfo.find(a=>a.symbol== 'USDC')
    },
  ];
  //

  const hanldeClickDropDown = (item: typeof actions[number]) => {
    return;
  };

  const renderMenu = () => {

    console.log(balanceInfo);
    return (
      <NcDropDown
        className={`text-neutral-500 dark:text-neutral-400 flex items-center justify-center rounded-full  ${containerClassName} ${twFocusClass()}`}
        iconClass={iconClass}
        data={actions}
        panelMenusClass={
          dropdownPositon === "up" ? "origin-bottom-right bottom-0" : undefined
        }
        onClick={hanldeClickDropDown}
      />
    );
  };

  return (
    <div>
      {renderMenu()}
    </div>
  );
};

export default PostActionDropdown;
