import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Table from "components/CardNFT/TableInfo";
import {load_gu_card_info, gu_info, _decode} from 'api/gu_gameapi';

import {orderListWithCheapV2, tokenPrice} from 'api/guapi';
import {get_cards} from 'api/server_api';
import LoadingOverlay from 'react-loading-overlay';

export interface PageSearchV2Props {
  className?: string;
}

const Transfer: FC<PageSearchV2Props> = ({ className = "" }) => {

  const clicked = (item_name:string) => {
    window.open('https://godscombine.com?n='+item_name, '_blank');
  }

  const getPrices = async(name:string, id:string, ethPrice:number, godsPrice:number) =>{
    var list_func = [orderListWithCheapV2(name, Number(id), 'ETH'),
    orderListWithCheapV2(name, Number(id), 'GODS')
    ];
    var r:any[]= await Promise.all(list_func); 
    try {
      if(r.length>0) {
        if(r[0]!==undefined && r[1] !==undefined) {
          var card_price_eth = r[0][0].price_human*ethPrice;
          var card_price_gods= r[1][0].price_human*godsPrice;
          console.log(name, card_price_eth, card_price_gods);  
          var rate_ = card_price_gods / card_price_eth;
          return {
            idx:0,
            name,
            eth_price: card_price_eth,
            gods_price: card_price_gods,
            rate:rate_,
            gid:id
          }
        }
      }
      else {
        return undefined;
      }
    } catch (error) {
      console.log('error');
      return undefined;
    }
  }

  const test= async ()=>{
    var ethPrice = await tokenPrice('ETH');
    var godsPrice = await tokenPrice('GODS');
    if(isNaN(ethPrice)) ethPrice = Number(localStorage.getItem('ETH'));
    if(isNaN(godsPrice)) godsPrice = Number(localStorage.getItem('GODS'));

    var list_func = [];

    var cards = await get_cards();
    cards = cards.slice(0,100);
    
    for (const card of cards) {
      try {
        var info_ =  Object.values(gu_info).find((a:any)=> Number(a.gid) === card.id);
        list_func.push(getPrices(info_.name, info_.gid, ethPrice, godsPrice));
      } catch (error) {
      }
    }

    // for(const [key, info_] of Object.entries(gu_info)) {
    //   idx++;
    //   if (idx>100) break;
    //   list_func.push(getPrices(info_.name, info_.gid, ethPrice, godsPrice));
    // }
    var list_cards:any[]= await Promise.all(list_func); 
    list_cards = list_cards.filter(a=>a!==undefined)
    list_cards.sort((a, b) => b.eth_price - a.eth_price && b.rate - a.rate);
    var idx_ = 1;
    list_cards.map(a=>{
      a.idx = idx_;
      idx_++;
      return a;
    })
    console.log(list_cards);
    setCards(list_cards);
    setLoading(false);
  }

  useEffect(() => {
    if(started == false) {
      setLoading(true);
      console.log('init once');
      if(Object.values(gu_info).length==0) {
        load_gu_card_info().then(a=>{
          console.log('guinfo', gu_info);
          test();
          /// search prices
        });
      }
      else {
        test();
      }
      setStarted(true);
    }
  })
  const [loading, setLoading] = useState<boolean>(false);
  const [started, setStarted] = useState(false);
  const [cards, setCards] = useState<any[]>([]);
  // const [ethPrice, setEthPrice] = useState(0);
  // const [godsPrice, setGodsPrice] = useState(0);

  return (
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      {/* <HeadBackgroundCommon className="h-24 2xl:h-28" /> */}
      <Helmet>
        <title>GodsCombine</title>
      </Helmet>
      <div className="container">
        
      Popular cards with a large price difference



      <LoadingOverlay
        active={loading}
        spinner
        text='Loading..'
        >
      </LoadingOverlay>
        
        <div className="mt-4 container max-w-2l mx-auto">
        <main>
          {/* LOOP ITEMS */}
          <Table orders={cards} clicked = {clicked}/>
        </main>
      </div>

      </div>
    </div>
  );
};

export default Transfer;
