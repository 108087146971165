import React, { FC, useEffect, useRef, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import Card11 from "components/CardNFT/Card11";
import { NFTType } from "data/types";

export interface ModalReportItemProps {
  show: boolean;
  onCloseModalReportItem: () => void;
  imageId:string;
  price:string;
}

const ModalReportItem: FC<ModalReportItemProps> = ({
  show,
  onCloseModalReportItem,
  imageId,
  price
}) => {
  const renderContent = () => {

    var t_:NFTType = {
      name:imageId,
      bgImage:`https://card.godsunchained.com/?id=${imageId}&q=4`,
      proto:Number(imageId)
    }
    return (
      <div>
        <Card11 key={imageId} post={t_}/>
        <div className="mt-5">
          Price: ${price}
        </div>
      </div>
    );
  };
  const handleClose = () => {
    onCloseModalReportItem();
  }
  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={handleClose}
      contentExtraClass="max-w-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
  );
};

export default ModalReportItem;
