import React, { FC, useState, useEffect } from "react";
import { NFTType, OrderType } from "data/types";
import Input from "components/Input/Input";
import { Helmet } from "react-helmet";
import Card11 from "components/CardNFT/Card11";
import Table from "components/CardNFT/Table";
import ModalSell from "components/CardNFT/ModalSell";
import ModalTransfer from "components/CardNFT/ModalTransfer";
import Checkbox from "components/Checkbox/Checkbox";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox2";
import NcImage from "components/NcImage/NcImage";

import {load_gu_card_info, gu_info, _decode, token_price_global} from 'api/gu_gameapi';
import {get_cards, get_member} from 'api/server_api'
import {getAssetImage, orderListV2, tokenPrice, buyOrder, buyOrders, getAssetsByUser, buyLog} from 'api/guapi'
import { useAppSelector } from "app/hooks";
import { imxwallet_address } from "app/imxwallet/imxwallet";
import ButtonPrimary from "components/Button/ButtonPrimary";
import LoadingOverlay from 'react-loading-overlay';

import { useLocation } from "react-router-dom"
// import { logEvnet_ } from "api/firebase";
// import bgImg from "images/pexels-photo-2138922.jpeg"
import bgImg from "images/banner-001.png"
var is_init = false;

export interface PageSearchV2Props {
  className?: string;
}

type objtype={
  [index:string]:number
}

const FILTERS = [
  { name: "Meteorite" },
  { name: "Shadow" },
  { name: "Gold" },
  { name: "Diamond" },
];

const PageSearchV2: FC<PageSearchV2Props> = ({ className = "" }) => {
  const crypto_list = ['ETH','GODS','IMX','USDC'];
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const wallet_address = useAppSelector(imxwallet_address);
  const [nft, setNFT] = useState<NFTType>({name:'Test', bgImage:'',proto:0}); // image

  const [orders, setOrders] = useState<OrderType[]>([
    // {order_id:'1',token_type:'ETH',price:'1500',quantity:'1',soldout:false}
]); // order list

  const [fee, setFee] = useState<number>(0.0);
  const [connect_wallet, setConnectedWallet] = useState<string>('');

  const [orders_2, setOrders_2] = useState<OrderType[]>([]); // order list 2
  const [orders_all, setOrders_all] = useState<OrderType[]>([]); // order list 2

  const [selectedQuality, setSelected] = useState<number>(4); // selected quality

  const [loading, setLoading] = useState<boolean>(false);

  // update token price
  const [cryptoPrices, setCryptoPrice] = useState<number[]>([1,1,1,1]);
  const [cheapPriceNFT, setCheapPriceNFT] = useState<number[]>([1,1,1,1]);
  const [cheapPriceNFT_usd_array, setCheapPriceNFT_usd_array] = useState<string[]>(['','','','']);

  const [ cheap_prices_text, setCheapPriceText] = useState<string>('');
  // const [calculated, setCalculated] = useState<Boolean>(false);

  // checkbox bool array
  const [checkboxBoolarray, setCheckboxBoolArray] = useState<boolean[]>([true,true,true,true]);


  const [search_text, setMessage] = useState('');
  const [search_enter_text, setSearchEnter] = useState('');
  const [quantity_nft, setQuantity] = useState(1);

  const [userNFTIds, setUserNFTIds] = useState<string[]>([]);


  const [isSellDialog, setIsSellDialog] = useState(false);
  const closeModalSellDialog = () => setIsSellDialog(false);
  const openModalSellDialog = () => setIsSellDialog(true);


  const [isTransferDialog, setIsTransferDialog] = useState(false);
  const closeModalTransferDialog = () => setIsTransferDialog(false);
  const openModalTransferDialog = () => setIsTransferDialog(true);


  const updateUserNfts = async (name:string, proto:number, quality_:number) =>{
    /// get assets that user has
    const address = localStorage.getItem('WALLET_ADDRESS');
    if(address!=null){
      // update assets
      try {
        var result = await getAssetsByUser(address, name, proto, [quality_]);
          setUserNFTIds(result.nftIds);
        return result;
      } catch (error) {
      }
    }
    return undefined;
  }

  const getCryptoPrices= async ()=> {
    var list:number[] = [];
    for (const crypto of crypto_list) {
      var price_from_last:any = localStorage.getItem(crypto);
      if(price_from_last == undefined) price_from_last = NaN;
      var price = await tokenPrice(crypto);
      if(isNaN(price)) {
        list.push(price_from_last);
      }
      else {
        list.push(price);
      }
      if(isNaN(price)) localStorage.setItem(crypto, String(price));
    }
    console.log('crypto result', list)
    setCryptoPrice(list);
    return list;
  }


  const getOrders = async (nft_name:string, cryptoPrices_2:Array<number>, selected_:number) => {
    setLoading(true);
    console.log('search',nft_name, selected_);
    var info = await getAssetImage(nft_name, selected_);
    console.log('query',info, info.name);

    setNFT({
      name:info.name,
      bgImage:info.bgImage,
      proto:info.proto
    })
    var crypto2num:objtype={
      'ETH':0,'GODS':1,'IMX':2,'USDC':3
    }

    orderListV2(info.name, info.proto, [selected_]).then(r =>{
      console.log('cheap',r.cheapResult);
      var text_concat = ''
      var cheap_arr=[]
      var cheap_arr_str = [];
      for (const order of r.cheapResult) {
        cheap_arr.push(order.price_human);
        // var price_usd:string = (cryptoPrices_2[crypto2num[order.token_name]]* order.price_human*1).toFixed(2);
        var price_usd:string = ( (1.0+fee/100.0) *(cryptoPrices_2[crypto2num[order.token_name]])* order.price_human*1).toFixed(2);

        cheap_arr_str.push(price_usd);
        var text = `${order.token_name}: $${price_usd} | `;
        text_concat = text_concat.concat(text)
      }
      text_concat = '| '.concat(text_concat);
      console.log('cheap 2',text_concat, cheap_arr);
      // setCheapPriceText(text_concat);
      setCheapPriceNFT(cheap_arr);
      setCheapPriceNFT_usd_array(cheap_arr_str);

      var result:Array<any>=  r.result.map( order =>{

        // include fee
        var price_usd:string = ( (1.0+fee/100.0) *(cryptoPrices_2[crypto2num[order.token_name]])* order.price_human*1).toFixed(2);
        var price_human = ((1.0+fee/100.0) *order.price_human).toFixed(6);
        var o:OrderType = {
          order_id:order.order_id,
          token_type: order.token_name,
          quantity: price_human,
          price: price_usd,
          soldout:false
        }
        return o;
      })
      // sorting
      result.sort((a,b) => Number(a.price)-Number(b.price));
      setOrders_all(result);


      /// use checkbox
      console.log('checkbox', checkboxBoolarray);
      var orders_all_copy = result.slice();

      for (let index = 0; index < checkboxBoolarray.length; index++) {
        const is_checked = checkboxBoolarray[index];
        if(is_checked === false) {
          orders_all_copy = orders_all_copy.filter(a=> a.token_type !== crypto_list[index])
        }
      }
      if(orders_all_copy !=undefined) {
        setOrders(orders_all_copy.slice(0,10));
        setOrders_2(orders_all_copy.slice(10,20));    
      }
      else {
      setOrders(result.slice(0,10));
      setOrders_2(result.slice(10,20));
      }
  



      setLoading(false);


      /// get assets that user has
      updateUserNfts(info.name, info.proto, selected_);
    });
  }

  const buyOrderWithIds = (cheapest:OrderType[]) =>{
    console.log(params.get('ref'));
    const ref= params.get('ref');
    if(ref != null) {
      // get_address(ref)
    }

    var orders_ = cheapest.map(a=>a.order_id);

    buyOrders(orders_, fee).then(r=>{
      console.log('result', r);
      //success
      var volume = 0;
      console.log(orders, orders_2);
      for(const [order_id, order_result] of Object.entries(r.result)) {
        var status:any = order_result.status;
        console.log(order_id,'status',status);
        if(status == 'success'){
          var idx= orders.findIndex(a=>a.order_id==order_id)
          if(idx !== -1) {
            orders[idx].soldout = true;
            setOrders(orders.slice());
            volume = volume + Number(orders[idx].price);
          }
          var idx_2= orders_2.findIndex(a=>a.order_id==order_id)
          console.log('@@@ success',idx, idx_2);
          if(idx_2 !== -1) {
            orders_2[idx_2].soldout = true;
            setOrders_2(orders_2.slice());
            volume = volume + Number(orders_2[idx_2].price);
          }
        }
      }
      buyLog(connect_wallet, volume);
    }).catch(r=>{
      console.log('buy result failed', r);
    });
  }

  const buyOrderWithId = (order_id:string) => {
    buyOrder(order_id, fee).then(r=>{
      // console.log('buy result', r);
      if(r.result[order_id].status ==='success'){

        // 구매완료 띄우기
        var idx= orders.findIndex(a=>a.order_id===order_id)
        if(idx !== -1) {
          orders[idx].soldout = true;
          setOrders(orders.slice());
          buyLog(connect_wallet, Number(orders[idx].price));
        }
        // 구매완료 띄우기
        var idx_2= orders_2.findIndex(a=>a.order_id===order_id)
        if(idx_2 !== -1) {
          orders_2[idx_2].soldout = true;
          setOrders_2(orders_2.slice());
          buyLog(connect_wallet, Number(orders_2[idx_2].price));
        }
      }
    }).catch(r=>{
      console.log('buy result failed', r);
    });
  }

  const handleQuantity= (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setQuantity(Number(event.target.value));
  };
  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessage(event.target.value);
  };

  const handleBuyButton = () => {
    console.log('onbuy', quantity_nft);
    console.log('checkboxBoolarray', checkboxBoolarray);

    var orders_all_copy = orders_all.slice();
    for (let index = 0; index < checkboxBoolarray.length; index++) {
      const is_checked = checkboxBoolarray[index];
      if(is_checked === false) {
        orders_all_copy = orders_all_copy.filter(a=> a.token_type !== crypto_list[index])
      }
    }

    var cheapest = orders_all_copy.filter(a=>a.soldout===false).slice(0,quantity_nft);
    buyOrderWithIds(cheapest);
  }

  const handleSellButton = () => {
    console.log('onsell');
    openModalSellDialog();
    // showing dialog
  }

  const handleTransfer = () => {
    setLoading(true);
    updateUserNfts(nft.name, nft.proto,selectedQuality).then(a=>{
      setLoading(false);
      openModalTransferDialog();
    });
  }

  const handleEnter = (e: { key: string; }) =>{
    if(e.key === 'Enter'){
      setSearchEnter(search_text);
      if(cryptoPrices[0]!== 1){
        console.log('pass get crypto', cryptoPrices);
        getOrders(search_text, cryptoPrices, selectedQuality);
      }
      else {
        console.log('get crypto');
        getCryptoPrices().then( (list) => {getOrders(search_text, list, selectedQuality);});
      }
    }
  }
  
  const handleSelected = (e: any) =>{
    console.log('selected',e);
    const quality2num:objtype ={'Meteorite':4,'Shadow':3,'Gold':2,'Diamond':1};
    setSelected(quality2num[e.name]);
    getOrders(search_text, cryptoPrices, quality2num[e.name]);
  }

  const handleTableClicked = (e:any) =>{
    console.log('wallet_address', wallet_address);
    console.log('table clicked', e);
    buyOrderWithId(e);
  }

  const handleCheckBox = (name:string, e:any) => {
    console.log('checkbox', name, e.target.checked);
    console.log('all result', orders_all);
    var crypto2num:objtype={
      'eth':0,'gods':1,'imx':2,'usdc':3
    }

    checkboxBoolarray[crypto2num[name]] = e.target.checked;
    var orders_all_copy = orders_all.slice();

    console.log('checkboxBoolarray', checkboxBoolarray);
    setCheckboxBoolArray(checkboxBoolarray);
    for (let index = 0; index < checkboxBoolarray.length; index++) {
      const is_checked = checkboxBoolarray[index];
      if(is_checked === false) {
        orders_all_copy = orders_all_copy.filter(a=> a.token_type !== crypto_list[index])
      }
    }
    setOrders(orders_all_copy.slice(0,10));
    setOrders_2(orders_all_copy.slice(10,20));    
  }

  const checkboxView = () =>{
    console.log(cheapPriceNFT_usd_array);
    if(cheapPriceNFT_usd_array[0]!== ''){
    }
    return(
      <div>
        <label className="inline-block mr-2">
          <Checkbox label={`ETH: $${cheapPriceNFT_usd_array[0]}`} name="eth" callback={handleCheckBox}></Checkbox>
        </label>
        <label className="inline-block mr-2">
          <Checkbox label={`GODS: $${cheapPriceNFT_usd_array[1]}`} name="gods" callback={handleCheckBox}></Checkbox>
        </label>
        <label className="inline-block mr-2">
          <Checkbox label={`IMX: $${cheapPriceNFT_usd_array[2]}`} name="imx" callback={handleCheckBox}></Checkbox>
        </label>
        <label className="inline-block">
          <Checkbox label={`USDC: $${cheapPriceNFT_usd_array[3]}`} name="usdc" callback={handleCheckBox}></Checkbox>
        </label>
      </div>
    );
  }

  const search_init =(card:any) =>{
    var info_ =  Object.values(gu_info).find((a:any)=> Number(a.gid) === card.id);
    if(info_ != undefined) {
      setMessage(info_.name);
      setSearchEnter(info_.name);
      getCryptoPrices().then( (list) => {getOrders(info_.name, list, 4);});
    }
    else{
      setMessage('demo');
      setSearchEnter('demo');
      getCryptoPrices().then( (list) => {getOrders('demo', list, 4);});
    }
  }

  useEffect(() => {
    // console.log('init');

    if(loading === false && search_text===''){
      if(is_init === true) return;
      is_init = true;





      setLoading(true);
      const address = localStorage.getItem('WALLET_ADDRESS');
      if(address!=null){
        setConnectedWallet(address);
        /// get member
        get_member(address.toLowerCase()).then(info=>{
          if(info.result === true) {
            // 한번이라도 거래한사람
            try {
              console.log('info', info, info.data[0].cnt);
              var cnt = info.data[0].cnt;
              if(cnt>5){
                setFee(0.5);
              }
            } catch (error) {
              // error 발생시 0%로 한다.
              setFee(0.0);
            }
          }
          else {
            // 한번도 거래하지않은사람
            setFee(0.0);
          }
        });
      }

      const name_from_url = params.get('n');
      if(name_from_url != null) {
        setMessage(name_from_url);
        setSearchEnter(name_from_url);
        getCryptoPrices().then( (list) => {getOrders(name_from_url, list, 4);});
      }
      else {
        get_cards().then((cards:any)=>{
          if(cards!==undefined){
            var t= cards.slice(0,20)[Math.round(Math.random()*19)]
            if(Object.values(gu_info).length==0) {
              load_gu_card_info().then(()=>{
                search_init(t);
              })
            }
            else{
              search_init(t);
            }
          }
          else{
            setMessage('demo');
            setSearchEnter('demo');
            getCryptoPrices().then( (list) => {getOrders('demo', list, 4);});
          }
        })
      }
    }
  })

  return (
    <div className={`nc-PageSearchV2 ${className}`} data-nc-id="PageSearchV2">
      {/* <HeadBackgroundCommon className="h-24 2xl:h-28" /> */}
      <Helmet>
        <title>GodsCombine</title>
      </Helmet>
      <div className="container">
        <LoadingOverlay
          active={loading}
          spinner
          text='Loading..'
          >
        </LoadingOverlay>

        <ModalSell
        quality = {selectedQuality}
        fee = {fee}
        nftIds ={userNFTIds}
        show={(isSellDialog)}
        onCloseModalReportItem={closeModalSellDialog}
        cryptoPrices = {cryptoPrices}
        cheapNFTPrices = {cheapPriceNFT}
        />

        <ModalTransfer
        nftIds ={userNFTIds}
        show={(isTransferDialog)}
        onCloseModalReportItem={closeModalTransferDialog}
        cryptoPrices = {cryptoPrices}
        cheapNFTPrices = {cheapPriceNFT}
        />        

        <header className="max-w-2xl mx-auto mt-10 flex flex-col lg:-mt-15">

        <div className="rounded-3xl relative w-full h-16">
          {/* <NcImage
            containerClassName="absolute inset-0"
            src={bgImg}
            className="object-cover w-full h-full"
          /> */}
          <h1 className="absolute inset-y-0 left-50 
          block text-sm">Marketplace fee: {fee}%</h1>

          <h1 className="absolute inset-y-5 left-50 
          block text-lg ">Search/Compare/Trade</h1>
        </div>

          <div className="relative">
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                id="search-input"
                type="search"
                placeholder="Type and press enter"
                className="shadow-lg rounded-xl border-opacity-0"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                name="message"
                onChange={handleChange}
                value={search_text}
                onKeyPress={handleEnter}
              />
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
          <span className="block text-sm mt-4 text-neutral-500 dark:text-neutral-300">
            Results for{" "}
            <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
              "{search_enter_text}"
            </strong>
          . You can purchase by clicking buy or the listed price.
          </span>
          {/* <span className="block text-sm mt-4 text-neutral-500 dark:text-neutral-300">
            <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
              {cheap_prices_text}
            </strong>           
          </span>     */}
          {checkboxView()}

      
          <div className="flex mt-3 justify-end">
            <div className="flex w-10 mr-80">
              <input className="mb-1 mt-1 mr-3 w-20 dark:text-neutral-900" type='number' value={quantity_nft} onChange={handleQuantity}/>
              <ButtonPrimary className="mr-3" onClick={handleBuyButton}>Buy</ButtonPrimary>
              <ButtonPrimary className="mr-3" onClick={handleSellButton} >Sell</ButtonPrimary>
              <ButtonPrimary className="mr-5" onClick={handleTransfer} >Send</ButtonPrimary>
            </div>
            <div className="ml-2 mt-1">
              <ArchiveFilterListBox lists={FILTERS} handleSelected={handleSelected}/>
            </div>
          </div>
        </header>
      </div>
      <div className="container max-w-2l mx-auto">
        <main>
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-8 mt-8 lg:mt-10">
            <div>
              <Card11 key={nft.name} post={nft} />
            </div>
            <Table orders={orders} clicked = {handleTableClicked}/>
            <Table orders={orders_2} clicked = {handleTableClicked}/>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSearchV2;
