import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import { NFTType } from "data/types";
import rightImg from "images/SVG-subcribe2.png";

export interface PostFeaturedMediaProps {
  className?: string;
  post: NFTType;
  isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
  className = "w-full h-full",
  post,
  isHover = false,
}) => {
  const { bgImage} = post;
  return (
    <div
      className={`nc-PostFeaturedMedia relative ${className}`}
      data-nc-id="PostFeaturedMedia"
    >
      <NcImage containerClassName="absolute inset-0" src={bgImage==''? rightImg:bgImage} />
    </div>
  );
};

export default PostFeaturedMedia;
